import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/1440p_Series_CGI_List/NavButtons';
export const _frontmatter = {
  "title": "1440p CGI Commands",
  "path": "/1440p_Series_CGI_List/System_Menu/User/",
  "dateChanged": "2018-03-23",
  "author": "Mike Polinowski",
  "excerpt": "Camera API control for developers",
  "image": "../../AU_SearchThumb_CGICommands_1080p.png",
  "social": "/images/Search/AU_SearchThumb_CGICommands_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_CGIs_white.webp",
  "chapter": "1440p CGI Commands"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-system-menu--user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-system-menu--user",
        "aria-label": "the system menu  user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The System Menu :: User`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-system-menu--user"
        }}>{`The System Menu :: User`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetuserattr"
            }}>{`param.cgi?cmd=getuserattr`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-your-cameras-network-configuration"
                }}>{`Get your Camera´s Network Configuration`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdadduser"
            }}>{`param.cgi?cmd=adduser`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#add-a-camera-user"
                }}>{`Add a camera user`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-1"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmddeluser"
            }}>{`param.cgi?cmd=deluser`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#add-a-camera-user-1"
                }}>{`Add a camera user`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-2"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetauthexpire"
            }}>{`param.cgi?cmd=getauthexpire`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-authentication-token-expiration"
                }}>{`Get Authentication Token Expiration`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-3"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#logincgi"
            }}>{`login.cgi`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-active-user-attributes"
                }}>{`Get active user attributes`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-4"
                }}>{`Example`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#aquire-ipc_token-for-user-session"
                    }}>{`Aquire ipc`}{`_`}{`token for User Session`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#get-active-user"
                    }}>{`Get active User`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgettoken"
            }}>{`param.cgi?cmd=gettoken`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-a-access-token-for-application-logins"
                }}>{`Get a Access Token for Application Logins`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-5"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetsession"
            }}>{`param.cgi?cmd=getsession`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-active-session"
                }}>{`Get active Session`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-6"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgettokeninfo"
            }}>{`param.cgi?cmd=gettokeninfo`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-all-active-access-token"
                }}>{`Get all active Access Token`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-7"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmddeltoken"
            }}>{`param.cgi?cmd=deltoken`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#delete-user-access-token"
                }}>{`Delete User Access Token`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-8"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetsessioninfo"
            }}>{`param.cgi?cmd=getsessioninfo`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-session-info"
                }}>{`Get Session Info`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-9"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdgetuserinfo"
            }}>{`param.cgi?cmd=getuserinfo`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#get-user-info"
                }}>{`Get User Info`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#example-10"
                }}>{`Example`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdsetuserreset"
            }}>{`param.cgi?cmd=setuserreset`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#set-up-password-recovery"
                }}>{`Set up Password Recovery`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdrequestreset"
            }}>{`param.cgi?cmd=requestreset`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#request-a-password-recovery"
                }}>{`Request a Password Recovery`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#paramcgicmdresetpassword"
            }}>{`param.cgi?cmd=resetpassword`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#reset-your-password"
                }}>{`Reset your Password`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <hr></hr>
    <h2 {...{
      "id": "paramcgicmdgetuserattr",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetuserattr",
        "aria-label": "paramcgicmdgetuserattr permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getuserattr`}</h2>
    <h3 {...{
      "id": "get-your-cameras-user-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-your-cameras-user-configuration",
        "aria-label": "get your cameras user configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get your Camera´s User Configuration`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Username for User`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_password`}</code>{`: Password for User`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authlevel`}</code>{`: User Authorisation: admin/user/guest `}{`[0 - 327679]`}{`*`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_enable`}</code>{`: De/Activate `}{`[0, 1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authexpire`}</code>{`: Login token expiration - 1 day, 3 days, 5 days, 7 days, 30 days, 365 days `}{`[0-5]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_lancode`}</code>{`: Preferred webUI language: `}{`[`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`de`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`en`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`fr`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cn`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`pl`}</code>{`]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_enc`}</code>{`: Stored password encryption: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`0`}</code>{` none, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{` md5 hash, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` encrypted `}{`[0-2]`}</li>
    </ul>
    <p>{`*`}{` `}<strong parentName="p">{`User Access Rights`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Live Stream: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`32`}</code></li>
      <li parentName="ul">{`RTSP Stream: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`64`}</code></li>
      <li parentName="ul">{`P2P Connection: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8`}</code></li>
      <li parentName="ul">{`Alarm Settings: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8192`}</code></li>
      <li parentName="ul">{`Multimedia Settings: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`4096`}</code></li>
      <li parentName="ul">{`PTZ Settings: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`16384`}</code>{` `}</li>
      <li parentName="ul">{`User Management: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code></li>
      <li parentName="ul">{`Push Notifications: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`16`}</code></li>
      <li parentName="ul">{`SD Viewer: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`128`}</code></li>
      <li parentName="ul">{`Audio Features: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`256`}</code></li>
      <li parentName="ul">{`Alarm Relay: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`512`}</code></li>
      <li parentName="ul">{`Whitelight LEDs: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1024`}</code></li>
      <li parentName="ul">{`PTZ Control: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2048`}</code></li>
    </ul>
    <p>{`*`}{` `}<strong parentName="p">{`User Presets`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Super Admin: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`327679`}</code>
        <ul parentName="li">
          <li parentName="ul">{`Access Rights: Minimal, User Management, Basic Authentication, Live Video, RTSP Video, SD Viewer, Audio Features, Alarm Relay, PTZ Control, Multimedia, Alarm Settings, PTZ Settings, System Settings, ONVIF (Admin), P2P Connection, Push Notifications`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Operator: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`4087`}</code>
        <ul parentName="li">
          <li parentName="ul">{`Access Rights: Minimal, User Management, Basic Authentication, Live Video, RTSP Video, SD Viewer, Audio Features, Alarm Relay, PTZ Control, Push Notifications`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Guest: `}<em parentName="li">{`authlevel`}</em>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`101`}</code>
        <ul parentName="li">
          <li parentName="ul">{`Access Rights: Minimal, Basic Authentication, Live Video, RTSP Video`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getuserattr

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getuserattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_enable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authlevel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"327679"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authexpire`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_lancode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"operator"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_enable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authlevel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4087"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authexpire`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_lancode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"guest"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_enable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authlevel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"101"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authexpire`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_lancode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"player1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_enable`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authlevel`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"3109"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_authexpire`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
at_lancode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"en"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getuserattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getuserattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"327679"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authexpire`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_lancode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setuserattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`newpassword`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setuserattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setuserattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`guest`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`guest`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setuserattr"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdadduser",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdadduser",
        "aria-label": "paramcgicmdadduser permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=adduser`}</h2>
    <h3 {...{
      "id": "add-a-camera-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera-user",
        "aria-label": "add a camera user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a camera user`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Username for User`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_password`}</code>{`: Password for User`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authexpire`}</code>{`: Access token expiration`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_lancode`}</code>{`: Default UI language`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authlevel`}</code>{`: User Authorization: admin/user/guest `}{`[15, 3, 1]`}</li>
    </ul>
    <h3 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`adduser`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mypassword`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"adduser"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmddeluser",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmddeluser",
        "aria-label": "paramcgicmddeluser permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=deluser`}</h2>
    <h3 {...{
      "id": "delete-a-camera-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#delete-a-camera-user",
        "aria-label": "delete a camera user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Delete a camera user`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_userid`}</code>{`: User ID number `}{`[1-999]`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`The admin user with user ID `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`1`}</code>{` cannot be deleted. Use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`cmd=setuserattr`}</code>{` to deactivate user if needed.`}</p>
    </blockquote>
    <h3 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`deluser`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"deluser"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    {/*
     ## param.cgi?cmd=getauthexpire
     ### Get Authentication Token Expiration
     * `admin`, `operator`, `guest`:
        * `0` = 1 day
        * `1` = 3 days
        * `2` = 5 days
        * `3` = 1 week
        * `4` = 1 month
        * `5` = 1 year
     Once logged in - e.g. to the camera webUI - with an `admin`, `operator` or `guest` the generated authentication token will expire in the here set amount of days. Forcing the user to log in again using their username and password. Be aware that session tokens might be deleted e.g. when closing your web browser.
     
     ### Example
     ```bash
     GET: http://admin:instar@192.168.2.168/param.cgi?cmd=getauthexpire
     cmd="getauthexpire";
     admin="0";
     operator="0";
     guest="0";
     response="200";
     ```
     
     ```bash
     GET: http://192.168.2.168/param.cgi?cmd=getauthexpire&at_userid=1&user=admin&pwd=instar
     cmd="getauthexpire";
     admin="0";
     operator="0";
     guest="0";
     response="200";
     ```
     
     ```bash
     SET: http://admin:instar@192.168.2.168/param.cgi?cmd=setauthexpire&user=5
     cmd="setauthexpire";
     response="200";
     ```
     */}
    <h2 {...{
      "id": "logincgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#logincgi",
        "aria-label": "logincgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`login.cgi`}</h2>
    <h3 {...{
      "id": "get-active-user-attributes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-active-user-attributes",
        "aria-label": "get active user attributes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get active user attributes`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Username for Active User`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authlevel`}</code>{`: 15: admin, 3: user, 1: visitor`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_enable`}</code>{`: De/Activate `}{`[0, 1]`}</li>
    </ul>
    <h3 {...{
      "id": "example-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-3",
        "aria-label": "example 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <h4 {...{
      "id": "aquire-ipc_token-for-user-session",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#aquire-ipc_token-for-user-session",
        "aria-label": "aquire ipc_token for user session permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Aquire ipc_token for User Session`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/param.cgi`}</code>{`: CGI command to get/set parameters and execute certain features (requires Basic-Auth)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/webui.cgi`}</code>{`: Alternative to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/param.cgi`}</code>{` used in the webUI to prevent Basic Auth prompt from the web browser (requires ipc_token that is aquired from login.cgi)`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -u admin:instar `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'http://192.168.2.115/login.cgi'`}</span>{` -v
*   Trying `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115:80`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
* Connected to `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` port `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#0)`}</span>{`
* Server auth using Basic with user `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` GET /login.cgi HTTP/1.1
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Host: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Authorization: Basic YWRtaW46aW5zdGFy
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` User-Agent: curl/7.78.0
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Accept: */*
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` 
* Mark bundle as not supporting multiuse
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` HTTP/1.1 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span>{` OK
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Content-Type: text/plain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`charset`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`utf-8
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Set-Cookie: `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ipc_token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`zRKSrHvFU5gVGaHsEBu8wvSnLcX9JchKpB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`expires`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Sat, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`23`}</span>{`-Apr-2022 `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{`:41:39 CEST
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Accept-Ranges: bytes
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Content-Length: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`75`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Date: Fri, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2022`}</span>{` 09:41:39 GMT
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` Server: lighttpd/1.4.60
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` 
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
* Connection `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#0 to host 192.168.2.115 left intact`}</span></code></pre></div>
    <h4 {...{
      "id": "get-active-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#get-active-user",
        "aria-label": "get active user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get active User`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/login.cgi

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/login.cgi`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"15"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgettoken",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgettoken",
        "aria-label": "paramcgicmdgettoken permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=gettoken`}</h2>
    <h3 {...{
      "id": "get-a-access-token-for-application-logins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-a-access-token-for-application-logins",
        "aria-label": "get a access token for application logins permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get a Access Token for Application Logins`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`token`}</code>{`: Access token`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`validity`}</code>{`: Token validity`}</li>
    </ul>
    <h3 {...{
      "id": "example-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-4",
        "aria-label": "example 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gettoken

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gettoken"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"m82D0CW7BvTsCSeLQgr63PlMLS0P7xfRbq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gettoken`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gettoken"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"m82D0CW7BvTsCSeLQgr63PlMLS0P7xfRbq"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetsession",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetsession",
        "aria-label": "paramcgicmdgetsession permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getsession`}</h2>
    <h3 {...{
      "id": "get-active-session",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-active-session",
        "aria-label": "get active session permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get active Session`}</h3>
    <p><em parentName="p">{`(for guest user level)`}</em></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`token`}</code>{`: Access token`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`validity`}</code>{`: Token validity`}</li>
    </ul>
    <h3 {...{
      "id": "example-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-5",
        "aria-label": "example 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <p><em parentName="p">{`(needs guest login!)`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsession

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsession"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9JAPJ6swdYlJfDG409LXQQe6OcetKtjGYT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651566086"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsession`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsession"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9JAPJ6swdYlJfDG409LXQQe6OcetKtjGYT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651566086"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgettokeninfo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgettokeninfo",
        "aria-label": "paramcgicmdgettokeninfo permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=gettokeninfo`}</h2>
    <h3 {...{
      "id": "get-all-active-access-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-all-active-access-token",
        "aria-label": "get all active access token permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get all active Access Token`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`validity`}</code>{`: Token validity`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`name`}</code>{`: Token name`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`note`}</code>{`: Token note`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`[]`}</code>{`: Token ID `}{`[0 - ∞]`}</li>
    </ul>
    <h3 {...{
      "id": "example-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-6",
        "aria-label": "example 6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gettokeninfo

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gettokeninfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
validity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651557518"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"session token"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
note`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
validity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"default"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
note`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`gettokeninfo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"gettokeninfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
validity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2147483647"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar-mqtt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
note`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Mqtt access token"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
validity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"default"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
note`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmddeltoken",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmddeltoken",
        "aria-label": "paramcgicmddeltoken permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=deltoken`}</h2>
    <h3 {...{
      "id": "delete-user-access-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#delete-user-access-token",
        "aria-label": "delete user access token permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Delete User Access Token`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`id`}</code>{`: Deletes token with specified ID`}</li>
    </ul>
    <h3 {...{
      "id": "example-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-7",
        "aria-label": "example 7 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`deltoken`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"deltoken"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`deltoken`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"deltoken"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetsessioninfo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetsessioninfo",
        "aria-label": "paramcgicmdgetsessioninfo permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getsessioninfo`}</h2>
    <h3 {...{
      "id": "get-session-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-session-info",
        "aria-label": "get session info permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get Session Info`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`validity`}</code>{`: Shows expire time (Unix) of current session token`}</li>
    </ul>
    <h3 {...{
      "id": "example-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-8",
        "aria-label": "example 8 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsessioninfo

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsessioninfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getsessioninfo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getsessioninfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`validity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1651564834"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetuserinfo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetuserinfo",
        "aria-label": "paramcgicmdgetuserinfo permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getuserinfo`}</h2>
    <h3 {...{
      "id": "get-user-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#get-user-info",
        "aria-label": "get user info permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get User Info`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_userid`}</code>{`: Active user ID`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Active user name`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authlevel`}</code>{`: Active user authentication level`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_enable`}</code>{`: Active user active status`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_lancode`}</code>{`: Active user webUI language code`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_authexpire`}</code>{`: Active user authentication token expiration`}</li>
    </ul>
    <h3 {...{
      "id": "example-9",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-9",
        "aria-label": "example 9 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getuserinfo

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getuserinfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"212991"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_lancode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authexpire`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getuserinfo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getuserinfo"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authlevel`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"212991"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_lancode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"de"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_authexpire`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdgetadduserdata",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdgetadduserdata",
        "aria-label": "paramcgicmdgetadduserdata permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=getadduserdata`}</h2>
    <h3 {...{
      "id": "set-up-password-recovery--2fa",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-up-password-recovery--2fa",
        "aria-label": "set up password recovery  2fa permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up Password Recovery & 2fa`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_userid`}</code>{`: User ID `}{`[1 - 999]`}{` (use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cmd=getuserattr`}</code>{` to find your user ID)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_mail`}</code>{`: Recovery Email address`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_question`}</code>{`: Recovery question`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_twofactor`}</code>{`: Two-factor authencation enabled `}{`[0,1]`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_sec`}</code>{`: Recovery question set `}{`[0,1]`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`When sending `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`getsession`}</code>{` or `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`gettoken`}</code>{`, the camera will check whether twofactor is enabled. If yes and there was no code generated, the camera will generate one and send it to the registered mail address. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`getsession`}</code>{` / `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`gettoken`}</code>{` will then return `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`208`}</code>{` - `}<strong parentName="p">{`MISSING`}{`_`}{`PARAM`}</strong>{`. By adding the twofactor code to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`getsession`}</code>{` / `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`gettoken`}</code>{` as `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&factor=<...>`}</code>{` it will go through. If a wrong code is added, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`208`}</code>{` will be returned again.`}</p>
    </blockquote>
    <h3 {...{
      "id": "example-10",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-10",
        "aria-label": "example 10 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getadduserdata

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getadduserdata"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_mail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"me@email.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_twofactor`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_sec`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`GET: http://192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`getadduserdata`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"getadduserdata"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_mail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"me@email.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_twofactor`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_sec`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setadduserdata`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_userid`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_mail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`me@email.com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_question`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"secretquestion"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_answer`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"secretanswer"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"setadduserdata"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    {/*
     <EuiSpacer />
     ## param.cgi?cmd=setuserreset
     ### Set up Password Recovery
     * `at_userid`: User ID [1 - 999] (use `cmd=getuserattr` to find your user ID)
     * `at_mail`: Recovery Email address
     * `at_question`: Recovery question (_optional_)
     * `at_answer`: Recovery answer to question above (_optional_)
     
     ```bash
     SET: http://admin:instar@192.168.2.168/param.cgi?cmd=setuserreset&at_userid=1&at_mail=myemail&at_question=myquestion&at_answer=myanswer
     cmd="setuserreset";
     response="200";
     ```
     */}
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdrequestreset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdrequestreset",
        "aria-label": "paramcgicmdrequestreset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=requestreset`}</h2>
    <h3 {...{
      "id": "request-a-password-recovery",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#request-a-password-recovery",
        "aria-label": "request a password recovery permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Request a Password Recovery`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Name of user account you want to recover`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_mail`}</code>{`: Recovery Email address`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_answer`}</code>{`: Recovery answer to question set with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cmd=setadduserdata`}</code>{` above`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`requestreset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_mail`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`myemail
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"requestreset"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`An email will be send to the email address with an recovery code - if the user account exists and has the recovery email configured using `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`cmd=setuserreset`}</code>{`.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "paramcgicmdresetpassword",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#paramcgicmdresetpassword",
        "aria-label": "paramcgicmdresetpassword permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`param.cgi?cmd=resetpassword`}</h2>
    <h3 {...{
      "id": "reset-your-password",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reset-your-password",
        "aria-label": "reset your password permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reset your Password`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_username`}</code>{`: Name of user account you want to recover`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_code`}</code>{`: Recovery code from the recovery email received after using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cmd=requestreset`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`at_password`}</code>{`: Add the new password you want to use for the recovered account`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`SET: http://admin:instar@192.168.2.168/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`resetpassword`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_answer`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`myanswer`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_code`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`at_password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`newpassword
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cmd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"resetpassword"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`response`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      